require('../css/result_table.css');
const all_value = "-1";
const all = '<option value="' + all_value + '">{{all}}</option>';
const female = 1;
const male = 0;

let $filterer;
let $categories;
let $workDays;
let $target;
let $noResults;

jQuery(document).ready(function () {
    $noResults = $('#notFound');
    $noResults.hide();
    $filterer = $('#seasonSelect');
    $categories = $('#categoriesSelect');
    let $categoriesOrigin = $('#categoriesSelectOrigin');
    filterComboBox($filterer, $categoriesOrigin, $categories, 'season', female);
    $workDays = $('#workdaySelect');
    let $workDaysOrigin = $('#workdaySelectOrigin');
    filterComboBox($filterer, $workDaysOrigin, $workDays, 'season', female);
    $filterer.change();
    let $queryButton = $('#queryButton');
    $target = $('#target');
    $queryButton.click(updateResults);
    updateResults();
});

function filterComboBox($filterer, $origin, $destination, filterBy, gender) {
    $filterer.change(function () {
        let id = $(this).find('option:selected')[0].value;
        let $originOptions = $origin.find('option');
        let data = '';
        for (let i = 0; i < $originOptions.length; i++) {
            if ($originOptions[i].dataset[filterBy] === id || id === all_value) {
                let newHTML = buildHTMLOption($originOptions[i]);
                data += newHTML;
            }
        }
        $destination.find('option').remove();
        $destination.append(buildAllHTMLOption(gender));
        $destination.append(data);
    });
}

function buildAllHTMLOption(gender) {
    let result;
    if (gender === female) {
        result = 'Todas';
    } else {
        result = 'Todos';
    }
    return all.replace('{{all}}', result);
}

function buildHTMLOption($field) {
    let name = $($field).attr('name');
    return '<option value=' + $field.value + '>' + name + '</option>';
}

function updateResults() {
    let seasonId = $filterer.find('option:selected')[0].value;
    let categoryId = $categories.find('option:selected')[0].value;
    let workDayId = $workDays.find('option:selected')[0].value;
    $('#resultRoot').hide();
    $('#loading').show();
    let $result = $.post('../results/search', {
        'season': seasonId,
        'division': categoryId,
        'workDay': workDayId
    }, function (data) {
        createFields(data, seasonId);
    }, 'json')
        .fail(function() {
            showNoResults();
        });
}

function showNoResults()
{
    $noResults.show();
    $('#loading').hide();
}

function createFields(data, seasonId) {
    $noResults.hide();
    let $rootElement = $('#resultRoot>div').empty();
    let expanded = true;
    let i = 0;
    let header = '';
    for (let key in data[seasonId]) {
        header += buildCollapsibleResultsFromTemplate($rootElement, i, key, data[seasonId][key], expanded, (expanded) ? 'show' : '');
        expanded = false;
        i++;
    }
    $rootElement.html(header);
    $('#loading').hide();
    $('#resultRoot').show();
}

function buildCollapsibleResultsFromTemplate($root, index, key, data, expanded, active) {
    let content = ' <div class="card"><div class="card-header" id=heading-' + index + '"><h2 class="mb-0"><button class="btn btn-link" ' +
        'type="button" data-toggle="collapse" data-target="#collapse-' + index + '' +
        '" aria-expanded="' + expanded + '" aria-controls="collapse-' + index + '">' + key + ' </button> </h2> </div>' +
        '<div id="collapse-' + index + '" class="collapse ' + active + '" aria-labelledby="heading-' + index + '" ' +
        'data-parent="#resultAccordion"><div class="card-body" id="card-body-' + index + '">';
    content += buildCollapsibleResultBody(data);
    content += '</div></div></div>';
    return content;

}

function buildCollapsibleResultBody(data) {
    let content = '';
    for (let day in data) {
        content += '<div class=col-md-12>';
        content += '<table class="table thead-light table-striped result-table"><thead><th colspan="3">Jornada número: ' + data[day].workDay['number'] + ' (' + data[day].workDay.date + ') </th></thead>';
        content += '<tbody>';
        for (let r in data[day].data) {
            let first_team = data[day].data[r].first_team;
            let second_team = data[day].data[r].second_team;
            let score_first = data[day].data[r].score_first;
            let score_second = data[day].data[r].score_second;
            if (score_first === null && score_second === null) {
                result = '-';
            } else {
                score_first = (score_first !== null) ? score_first : '0';
                score_second = (score_second !== null) ? score_second : '0';
                result = score_first + '-' + score_second;
            }
            content += '<tr class="line-result">';
            content += '<td>' + first_team + '</td>';
            content += '<td>' + second_team + '</td>';
            content += '<td>' + result + '</td>';
            content += '</tr>';
        }
        content += '</tbody></table></div>';
    }
    return content;
}